<template>
	<div id="editformtop" class="pa-4">
		<v-progress-linear
			indeterminate
			v-if="loading || loading_balance || loadingLicensing"
		/>
		<v-form
			v-if="!loading && !loading_balance && !loadingLicensing"
			lazy-validation
			v-model="isFormValid"
			ref="form2"
			:disabled="saving"
		>
			<v-img v-if="$vuetify.breakpoint.smAndUp" src="https://hq-image-cdn.azureedge.net/servers/production/cms_images/economy-a-leads-desktop_XtBXsg.png" style="width: 100%" />
			<v-img v-else src="https://hq-image-cdn.azureedge.net/servers/production/cms_images/economy-a-leads-mobile_F9Juin.png" style="width: 100%" />
			<v-row wrap v-if="account_problem && !loading_balance">
				<v-col cols="12">
					<h4>Credit Card Issue</h4>
					<p>
						There is an issue with your saved payment profile.
						Please add a valid payment profile before ordering
						leads. You can also visit your
						<router-link to="/user/account_balance"
							>payment profile page</router-link
						>
						for more information.
					</p>
					<q-save-payment-profile
						:agent-code="agentCode"
						:no-dialog="true"
						v-on:newcard="reset"
					></q-save-payment-profile>
				</v-col>
			</v-row>
			<v-row wrap v-else>
				<v-col cols="12">
					<h3>{{ title }}</h3>
				</v-col>
				<v-col cols="12" class="text-left">
					<div v-if="hasRole(['SuperAdmin', 'Staff'])">
						<v-select
							label="Order Status"
							v-model="order.OrderStatus"
							:items="status_items"
						></v-select>
						<v-text-field
							label="Order Date"
							v-model="order.OrderDate"
						></v-text-field>
					</div>
					<p>
						<v-icon left large>fas fa-check</v-icon>Valid credit
						card on file. Current balance is
						{{ formatCurrency(balance) }}
					</p>
					<div
						v-if="hasPermission('manage:LeadOrders')"
						class="text-center"
					>
						<v-btn @click="rollOverOrder(order.ID)">
							Roll Over Order
							<v-progress-circular
								indeterminate
								size="20"
								v-if="saving"
							></v-progress-circular>
						</v-btn>
					</div>
				</v-col>
				<v-col cols="12">
					<v-row>
						<v-col cols="12">
							The minimum quantity is 10 or the number of states
							you selected. Whichever is larger. For example:
							Select 3 states, then minimum quantity is 10. Select
							10 states, then minimum quantity is 10.
						</v-col>
					</v-row>
					<v-row
						v-for="(line, ix) in leadOrderLines"
						:key="`${line.ProductType}-${line.OrderLineStatus}`"
						dense
						:class="line.OrderLineStatus"
					>
						<v-col cols="4">
							<v-select
								v-model="line.State"
								:items="
									isNewOrder && activeLicenseInStates.length
										? activeLicenseInStates
										: allStates
								"
								multiple
								:messages="statesMessage"
								label="States*"
								:disabled="line.ID !== 'New'"
								:rules="[form_rules.required]"
							/>
						</v-col>
						<v-col cols="4">
							<v-select
								label="Product Type*"
								v-model="line.ProductType"
								:items="[
									{
										text: 'Life Insurance',
										value: 'MP/TL',
									},
								]"
								:rules="[rules.required]"
								:disabled="line.ID !== 'New'"
							/>
						</v-col>
						<v-col cols="3">
							<v-text-field
								:disabled="
									[
										'Open',
										'Needs Approval',
										'Paid Overflow',
										'Unpaid Overflow',
									].indexOf(line.OrderLineStatus) === -1
								"
								label="Quantity*"
								type="number"
								v-model.number="line.Qty"
								:rules="getQtyRules(line)"
							/>
						</v-col>
						<v-col cols="1" class="pt-5">
							<v-btn
								:disabled="
									leadOrderLines.length < 2 ||
									['Open', 'Needs Approval'].indexOf(
										line.OrderLineStatus
									) === -1
								"
								color="red"
								icon
								@click="removeLine(ix)"
							>
								<v-icon>fas fa-times-circle</v-icon>
							</v-btn>
						</v-col>
					</v-row>
					<div class="text-center">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									icon
									v-on="on"
									v-bind="attrs"
									@click="addNewLeadOrderLines"
								>
									<v-icon>fas fa-plus-circle</v-icon>
								</v-btn>
							</template>
							<span>Add a new order line</span>
						</v-tooltip>
					</div>
				</v-col>
				<v-col cols="12">
					<div
						class="scroll elevation-0"
						style="
							height: 150px;
							text-align: left;
							border: #ccc 1px solid;
						"
					>
						<page
							slug="digital-lead-order-agreement"
							:no-template="true"
							class="disclaimer-text"
						/>
					</div>
					<v-alert type="error" v-if="error">
						{{ error }}
					</v-alert>
					<v-alert type="warning" v-if="warning">
						{{ warning }}
					</v-alert>
					<v-divider class="ma-6"></v-divider>
					<h4>
						Total Leads Requested
						<span v-if="order.OrderType == 'Standing Order'">per Week</span>:
						{{ total_leads_requested }}
						<v-tooltip top v-if="total_leads_requested > 50">
							<template v-slot:activator="{ on, attrs }">
								<v-icon color="yellow" v-bind="attrs" v-on="on">fas fa-exclamation-triangle</v-icon>
							</template>
							<span>Max # Leads per order is 50!</span>
						</v-tooltip>
						<br />
						<span>
							Max cost will be
							<span class="green--text">
								{{ formatLongCurrency(total_cost) }}
							</span>
							per week.
						</span>
					</h4>
					<v-alert
						type="info"
						v-if="hasLHDiscountAvailable"
					>
						You will receive a promotional discount of
						<strong>
							{{ formatCurrency(balance_data.LHDiscount.discount) }}
							off per lead
						</strong>
						for your next
						<strong>
							{{ balance_data.LHDiscount.num_discount_leads_remaining	}}
							Economy leads
						</strong>
						allocated. The discount will be applied at the time of
						allocation.
					</v-alert>
					<div class="disclaimer-text cms">
						<v-checkbox
							v-model="accept_agreement"
							label="I agree to the Terms and Conditions outlined above, & understand I will be charged the full amount upon submitting this order to fund my account."
						>
						</v-checkbox>
						<span class="full-width-text">
							<p>
								For a downloadable copy of these Terms and
								Conditions please visit:
								<a
									href="https://hq.quility.com/cms/document/view/156003"
									target="_blank">
									https://hq.quility.com/cms/document/view/156003
								</a>
							</p>
						</span>
					</div>

					<v-btn
						:disabled="saving || !accept_agreement"
						large
						color="primary"
						@click="saveOrder"
					>
						<span v-if="isNewOrder">
							Place {{ order.OrderType }} Order
						</span>
						<span v-else>Save Changes</span>
						<v-progress-circular
							indeterminate
							size="20"
							v-if="saving"
						/>
					</v-btn>
					<v-btn class="ml-3" @click="$emit('cancel')">Cancel</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</div>
</template>
<script>
import QuilityAPI from "@/store/API/QuilityAPI.js";
import StateOptions from "@/store/StateOptions";
import StateAbbreviations from "@/store/StateAbbreviations";
import rules from "@/components/utils/ValidationRules";
import Page from "@/CMS/views/index.vue";
import LeadPrices from "@/store/lead_pricing";
import LeadDiscounts from "@/store/lead_pricing_discounts";
import moment from "moment";
import QSavePaymentProfile from "@/components/authorizenet/QSavePaymentProfile.vue";
import { groupBy } from "lodash";

export default {
	props: {
		agentCode: {},
		id: {},
		leadType: { default: 'Economy' },
		allowNationWide: {},
		title: { required: true },
		description: { default: "" },
		leadLevel: { default: 'AA' },
	},
	data() {
		return {
			order: {},
			saving: false, //for progress bars
			warning: null,
			error: null,
			isFormValid: true, // whether the form is valid or not.
			rules: rules, //validation rules imported.
			loading: false, //progress indicator,
			creditcard_on_file: false,
			accept_agreement: false,
			contract_level: null,
			balance: null,
			balance_data: null,
			loading_balance: false,
			account_problem: true,
			account_problem_text: "",
			licensing: null,
			loadingLicensing: false,
			leadOrderLines: [],
		};
	},
	mounted() {
		this.reset();
		this.getLicensing();
	},
	computed: {
		isNewOrder() {
			return this.id === "new";
		},
		activeLicenseInStates() {
			// always return an array
			if (!this.licensing) {
				return [];
			}
			return (
				this.licensing.licenses
					?.filter(({ status }) => status === "Active")
					.map(({ state }) => state)
					.sort() || []
			);
		},
		statesMessage() {
			if (!this.activeLicenseInStates.length) {
				return "No States with valid licenses";
			}
			return [];
		},
		allStates() {
			const states = [...StateOptions].sort();
			if (this.allowNationWide || this.hasRole(["SuperAdmin", "Staff"])) {
				states.push("Nationwide");
			}
			return states;
		},
		product_type: function () {
			return "AA";
		},
		lead_level: function () {
			if (this.leadLevel) {
				return this.leadLevel;
			}
			return "DL";
		},
		total_leads_requested() {
			if (!this.leadOrderLines || !this.leadOrderLines.length) {
				return 0;
			}
			return this.leadOrderLines.reduce((acc, line) => {
				if (
					line.OrderLineStatus === "Open" ||
					line.OrderLineStatus === "Needs Approval"
				) {
					acc += line.Qty;
				}
				return acc;
			}, 0);
		},
		order_type_description: function () {
			if (this.order.OrderType == "One-time Order")
				return ["We no longer accept one0time orders."];
			return [
				"This will create a new standing order for the same states and quantities every week.",
			];
		},
		order_states: function () {
			return this.order.LeadOrderLines.map((a) => a.State);
		},
		status_items: function () {
			return [
				"Open",
				"Draft",
				"Complete",
				"Pending Cancellation",
				"Canceled",
				"New",
				"Paused by Agent",
				"Paused by Admin",
				"In Review",
				"Order Declined",
				"Requires Balance",
			];
		},
		lead_prices: function () {
			return LeadPrices;
		},
		total_cost() {
			if (this.lead_level !== "LH") {
				return this.formatLongCurrency(
					this.lead_prices[this.lead_level][this.contract_level] *
						this.total_leads_requested
				);
			}
			if (!this.leadOrderLines || !this.leadOrderLines.length) {
				return 0;
			}
			const total = this.leadOrderLines.reduce((acc, line) => {
				if (
					line.OrderLineStatus === "Open" ||
					line.OrderLineStatus === "Needs Approval"
				) {
					acc +=
						this.getLeadCost(line.ProductType, this.leadLevel) *
						line.Qty;
				}
				return acc;
			}, 0);
			return total;
		},
		hasLHDiscountAvailable() {
			if (this.balance_data == null) {
				return false;
			}
			if (this.balance_data.LHDiscount.discount <= 0) {
				return false;
			}
			var remaining =
				this.balance_data.LHDiscount.num_discount_leads_remaining;
			return remaining > 0;
		},
	},
	methods: {
		async getLicensing() {
			this.loadingLicensing = true;
			this.licensing = null;
			QuilityAPI.getAgentLicensing(
				{ AgentCode: this.agentCode },
				this.role
			)
				.then((response) => {
					this.licensing = response.data;
					this.preselectStates();
					this.loadingLicensing = false;
				})
				.catch((err) => {
					console.error(err);
					this.showError(err);
					this.loadingLicensing = false;
				});
		},
		preselectStates() {
			if (!this.isNewOrder) return;
			this.leadOrderLines.forEach((line) => {
				if (line.State.length) return;
				line.State = this.activeLicenseInStates.slice(0, 5);
			});
		},
		getStateAbbreviation(state) {
			if (this.states.indexOf(state) > -1) {
				return state;
			}
			if (typeof StateAbbreviations[state] != "undefined") {
				return StateAbbreviations[state];
			}
			return null;
		},
		reset: function () {
			this.saving = false;
			this.warning = null;
			this.accept_agreement = false;
			this.balance_data = null;
			this.balance = null;
			this.account_problem = true;
			this.account_problem_text = "";
			if (this.isNewOrder) {
				this.order = {
					OrderType: "Standing Order",
					AgentCode: this.agentCode,
					Qty: 0,
					LeadType: this.leadType,
					OrderDate: moment().format("YYYY-MM-DD H:mm:ss"),
					LeadOrderLines: []
				};
				// TODO: Check
				this.leadOrderLines = [];
				this.addNewLeadOrderLines();
				if (this.hasRole(["Staff", "SuperAdmin", "Exec"])) {
					this.order.OrderStatus = "New";
				}
			} else {
				this.creditcard_on_file = true;
				this.getleadOrderLines();
			}
			this.getAgentContractLevel(this.agentCode);
			this.checkForPaymentProfile(this.agentCode);
		},
		addNewLeadOrderLines() {
			this.leadOrderLines.push({
				ID: "New",
				LeadType: this.leadType,
				State: this.activeLicenseInStates.slice(0, 5),
				Qty: 10,
				ProductType: 'MP/TL',
				County: "STATEWIDE",
				OrderLineStatus:
					this.leadType == "Quility Digital Lead"
						? "Needs Approval"
						: "Open",
			});
		},
		removeLine(ix) {
			if (this.leadOrderLines[ix].ID === "New") {
				this.leadOrderLines.splice(ix, 1);
			} else {
				this.leadOrderLines[ix].OrderLineStatus = "Canceled";
			}
		},
		saveOrder: function () {
			var g = this;
			this.saving = true;
			if (!this.$refs["form2"].validate()) {
				this.warning = "Please correct invalid fields.";
				setTimeout(function () {
					g.warning = null;
				}, 6000);
				this.saving = false;
				return false;
			}
			//do not allow more than 50 leads per order.
			if (!this.hasRole(["Staff", "SuperAdmin", "Exec"])) {
				if (this.total_leads_requested > 50) {
					this.warning =
						"The max # leads per order is 50 leads. Please reduce the number of leads requested.";
					this.saving = false;
					return false;
				}
			}
			let the_call;
			this.order.LeadOrderLines = this.splitLeadOrderLines(
				this.leadOrderLines
			);
			if (this.isNewOrder) {
				the_call = QuilityAPI.placeLeadOrder(
					this.order.OrderType,
					this.order.LeadType,
					this.agentCode,
					this.order
				);
			} else {
				the_call = QuilityAPI.updateLeadOrder(this.id, this.order);
			}
			the_call
				.then(function (json) {
					if (json.success) {
						g.saving = false;
						if (g.id == "new") {
							g.showSuccess(
								"Success! Your order # is " +
									json.data.OrderNumber +
									". <br>It will need to be approved by the Home Office<br>before leads will be allocated."
							);
						} else {
							g.showSuccess("Your changes have been saved!");
						}
						g.$emit("order_saved");
					} else {
						g.saving = false;
						g.showError(
							"Oops! There was a problem placing your order.",
							json.msg
						);
					}
				})
				.catch(function (error) {
					g.saving = false;
					g.showError(
						"Oops! There was a problem placing your order.",
						error
					);
				});
		},
		splitLeadOrderLines(groupedLines) {
			const initialOrderLines = this.order.LeadOrderLines;
			const orderLines = groupedLines.reduce((acc, line) => {
				// distribution: 1 qty per state, the rest go to the last state in the list
				let qty = line.Qty;
				const splittedOrderLines = line.State.map((state, idx) => {
					const id =
						initialOrderLines.find(
							({ State, ProductType }) =>
								State === state &&
								ProductType === line.ProductType
						)?.ID || "New";
					const quantity = qty > 0 ? 1 : 0;
					const singleStateOrderLine = {
						...line,
						State: state,
						Qty: quantity,
						ID: id,
					};
					if (idx < line.State.length - 1) {
						qty = qty - quantity;
					} else {
						singleStateOrderLine.Qty = qty;
					}
					return singleStateOrderLine;
				});
				acc.push(...splittedOrderLines);
				return acc;
			}, []);
			return orderLines;
		},
		groupLeadOrderLines(splittedLines) {
			if (!splittedLines || !splittedLines.length) {
				return [];
			}
			const filtered = splittedLines.filter(
				(line) => line.OrderLineStatus !== "Canceled"
			);
			const groupedByProduct = groupBy(filtered, "ProductType");
			const orderLines = Object.entries(groupedByProduct)
				// sort by 'ProductType'
				.sort((a, b) => a[0] > b[0])
				// form array of lines
				.map(([, lines]) =>
					lines.reduce((acc, line, idx) => {
						if (idx === 0) {
							return { ...line, State: [line.State] };
						}
						acc.Qty += line.Qty;
						acc.State = [...acc.State, line.State];
						return acc;
					}, {})
				);
			return orderLines;
		},
		getleadOrderLines() {
			if (!this.isNewOrder) {
				this.leadOrderLines = this.groupLeadOrderLines(
					this.order.LeadOrderLines
				);
			}
		},
		goToTop: function () {
			this.$vuetify.goTo("#editformtop", {
				duration: 500,
				easing: "easeOutCubic",
			});
		},
		states: function (s) {
			var states = [...StateOptions];
			states.sort();
			if (
				this.allowNationWide === true ||
				this.hasRole(["Staff", "SuperAdmin"])
			) {
				states.push("Nationwide");
			}
			if (s != null) {
				return states;
			}
			//remove any states already in the list.
			return states.filter((val) => {
				return this.order_states.indexOf(val) == -1;
			});
		},
		getQtyRules(line) {
			if (
				line.OrderLineStatus === "Canceled" ||
				line.OrderLineStatus === "Closed" ||
				line.OrderLineStatus === "Complete"
			) {
				return [];
			}
			const rules = this.hasRole(["Staff", "SuperAdmin", "Exec"])
				? [this.form_rules.int, this.form_rules.required] // no limit for staff editing
				: [
						this.form_rules.int,
						this.form_rules.required,
						(v) =>	Number(v) >= 10 || "Minimum order per state is 10 Leads.",
				  ];

			rules.push(
				(v) =>
					Number(v) >= line.State.length ||
					"The order must be more than the number of states."
			);

			return rules;
		},
		getAgentContractLevel: function (agentcode) {
			if (typeof agentcode == "undefined") {
				return;
			}
			var g = this;
			g.loading = true;
			QuilityAPI.getAgentStateless(agentcode)
				.then(function (json) {
					g.loading = false;
					g.$set(g, "contract_level", json.ContractLevel);
				})
				.catch(function (err) {
					g.loading = false;
					this.showError(
						"Oops! There was a problem loading contract level.",
						err
					);
				});
		},
		getLeadCost: function (product, level, exclude_discount) {
			if (!product) {
				return 0;
			}
			var cost_per_lead = 0;
			if (level == "LH") {
				cost_per_lead =
					this.lead_prices[level][product][this.contract_level];
			} else {
				cost_per_lead = this.lead_prices[level][this.contract_level];
			}
			return cost_per_lead;
			// var discount = this.getDiscount(level)
			// return cost_per_lead - (cost_per_lead * discount)
		},
		getLeadDiscount: function (product, level) {
			return 0 // TODO: find correct discount
		},
		getDiscount: function (level) {
			var discount = 0;
			if (typeof LeadDiscounts[level] != "undefined") {
				//loop through each one and find the largest discount.
				LeadDiscounts[level].forEach(function (i) {
					var compareDate = moment();
					var startDate = moment(i["startDate"]);
					var endDate = moment(i["endDate"]);
					if (compareDate.isBetween(startDate, endDate)) {
						if (discount < i["discount_percentage_off"]) {
							discount = i["discount_percentage_off"];
						}
					}
				});
			}
			return discount;
		},
		checkForPaymentProfile: function (agent_code) {
			if (typeof agent_code == "undefined") {
				return;
			}
			var g = this;
			g.loading_balance = true;
			QuilityAPI.getAgentAccountBalance(agent_code)
				.then(function (json) {
					g.loading_balance = false;
					if (typeof json.msg != "undefined") {
						g.account_problem = true;
						g.account_problem_text = json.msg;
					}
					if (
						typeof json.response != "undefined" &&
						typeof json.response.body.message != "undefined"
					) {
						g.account_problem = true;
						g.account_problem_text = json.response.body.message;
					}
					if (json.data) {
						g.balance_data = json.data;
						g.balance = json.data.Balance;
						g.account_problem = false;
						g.account_problem_text = "";
					}
					if (json.data && json.data.AgentAccount == null) {
						g.account_problem = true;
						g.account_problem_text =
							"There is not default payment account.";
					}
				})
				.catch(function (err) {
					console.log("Maksim", err);
					console.log(err);
					g.showError(err);
				});
		},
		rollOverOrder: function (order_id) {
			if (typeof order_id == "undefined") {
				return;
			}
			var g = this;
			g.saving = true;
			QuilityAPI.rolloverLeadOrder(order_id)
				.then(function (json) {
					g.saving = false;
					if (json.success === true) {
						g.showSuccess(
							"Success! Lead Order had been rolled over for new allocations."
						);
						g.$emit("order_saved");
					} else {
						g.showError(
							"There was a problem rolling over this order!"
						);
					}
				})
				.catch(function (err) {
					console.log(err);
					g.showError(err);
				});
		},
	},
	watch: {
		agentCode() {
			this.reset();
			this.getLicensing();
		},
	},
	components: {
		Page,
		QSavePaymentProfile,
	},
};
</script>
<style>
.disclaimer-text.cms .full-width-text p {
	font-size: 0.7em;
}

.row.Canceled .col .v-select__selection,
.row.Canceled .col .v-text-field__slot {
	text-decoration: line-through;
}
</style>
