//make sure you update the back end pricing too! in /app/Services/AgentAccountService.php
export default {
    "IA": {
        "0": 54.56,
		"70": 54.56,
		"75": 54.56,
		"80": 54.56,
		"85": 57.97,
		"90": 61.38,
		"95": 64.79,
		"100": 68.20,
		"105": 71.61,
		"110": 75.02,
		"115": 78.43,
		"120": 81.84,
		"125": 85.25,
		"130": 88.66,
		"140": 88.66,
    },
    "DL": {
        "0": 54.56,
		"70": 54.56,
		"75": 33.39,
		"80": 35.61,
		"85": 37.84,
		"90": 40.06,
		"95": 42.29,
		"100": 44.52,
		"105": 46.74,
		"110": 48.97,
		"115": 51.19,
		"120": 53.42,
		"125": 55.75,
		"130": 58.07,
		"140": 61.88,
    },
    "LH": {
        "LHMP" : {
            '0' : 28.72,
            '70' : 28.72,
            '75' : 28.72,
            '80' : 28.72,
            '85' : 30.51,
            '90' : 32.31,
            '95' : 34.10,
            '100' : 35.90,
            '105' : 37.69,
            '110' : 39.49,
            '115' : 41.28,
            '120' : 43.08,
            '125' : 44.87,
            '130' : 46.67,
            '140' : 46.67,
        },
        "LHFE" : {
            '0' : 28.72,
            '70' : 28.72,
            '75' : 28.72,
            '80' : 28.72,
            '85' : 30.51,
            '90' : 32.31,
            '95' : 34.10,
            '100' : 35.90,
            '105' : 37.69,
            '110' : 39.49,
            '115' : 41.28,
            '120' : 43.08,
            '125' : 44.87,
            '130' : 46.67,
            '140' : 46.67,
        },
        "LHDFL" : {
            '0' : 45.00,
            '70' : 45.00,
            '75' : 45.00,
            '80' : 45.00,
            '85' : 45.00,
            '90' : 45.00,
            '95' : 45.00,
            '100' : 45.00,
            '105' : 45.00,
            '110' : 45.00,
            '115' : 45.00,
            '120' : 45.00,
            '125' : 45.00,
            '130' : 45.00,
            '140' : 45.00,
        },
        "LHGL" : {
            '0' : 28.72,
            '70' : 28.72,
            '75' : 28.72,
            '80' : 28.72,
            '85' : 30.51,
            '90' : 32.31,
            '95' : 34.10,
            '100' : 35.90,
            '105' : 37.69,
            '110' : 39.49,
            '115' : 41.28,
            '120' : 43.08,
            '125' : 44.87,
            '130' : 46.67,
            '140' : 46.67,
        },
        "LHRS" : {
            '70' : 149.00,
            '75' : 149.00,
            '80' : 149.00,
            '85' : 149.00,
            '90' : 149.00,
            '95' : 149.00,
            '100' : 149.00,
            '105' : 149.00,
            '110' : 149.00,
            '115' : 149.00,
            '120' : 149.00,
            '125' : 149.00,
            '130' : 149.00,
            '140' : 149.00,
        },
        "LHIB" : {
            '70' : 49.00,
            '75' : 49.00,
            '80' : 49.00,
            '85' : 49.00,
            '90' : 49.00,
            '95' : 49.00,
            '100' : 49.00,
            '105' : 49.00,
            '110' : 49.00,
            '115' : 49.00,
            '120' : 49.00,
            '125' : 49.00,
            '130' : 49.00,
            '140' : 49.00,
        },
        "LHIUL" : {
            '0' : 45.00,
            '70' : 45.00,
            '75' : 45.00,
            '80' : 45.00,
            '85' : 45.00,
            '90' : 45.00,
            '95' : 45.00,
            '100' : 45.00,
            '105' : 45.00,
            '110' : 45.00,
            '115' : 45.00,
            '120' : 45.00,
            '125' : 45.00,
            '130' : 45.00,
            '140' : 45.00,
        },
    },
    "CI" : {
        '0': 18.86,
		'70': 18.86,
        '75': 18.86,
        '80': 18.86,
        '85': 20.04,
        '90': 21.22,
        '95': 22.40,
        '100': 23.58,
        '105': 24.76,
        '110': 25.94,
        '115': 27.12,
        '120': 28.29,
        '125': 29.47,
        '130': 30.65,
        '140': 30.65,
    },
    "DX": {
        "0": 9.00,
        "70": 9.00,
        "75": 9.00,
        "80": 9.00,
        "85": 9.00,
        "90": 9.00,
        "95": 9.00,
        "100": 9.00,
        "105": 9.00,
        "110": 9.00,
        "115": 9.00,
        "120": 9.00,
		"125": 9.00,
		"130": 9.00,
        "140": 9.00,
    },
    "Recruiting": {
        "Licensed": {
            "0": 25.00,
            "70": 25.00,
            "75": 25.00,
            "80": 25.00,
            "85": 25.00,
            "90": 25.00,
            "95": 25.00,
            "100": 25.00,
            "105": 25.00,
            "110": 25.00,
            "115": 25.00,
            "120": 25.00,
			"125": 25.00,
			"130": 25.00,
            "140": 25.00,
        },
        "Unlicensed": {
            "0": 20.00,
            "70": 20.00,
            "75": 20.00,
            "80": 20.00,
            "85": 20.00,
            "90": 20.00,
            "95": 20.00,
            "100": 20.00,
            "105": 20.00,
            "110": 20.00,
            "115": 20.00,
            "120": 20.00,
			"125": 20.00,
			"130": 20.00,
            "140": 20.00,
        }
    },
	"AA": {
        '0': '4.10',
		'70': '4.10',
		'75': '4.10',
		'80': '4.10',
		'85': '4.36',
		'90': '4.62',
		'95': '4.87',
		'100': '5.13',
		'105': '5.39',
		'110': '5.64',
		'115': '5.90',
		'120': '6.16',
		'125': '6.41',
		'130': '6.67',
		'140': '6.67',
	}
}